<template>
  <div
    v-if="user.id"
    class="bg-white py-6 px-4 sm:p-6 shadow rounded-t max-w-4xl"
  >
    <form @submit.prevent="handleSubmit" class="space-y-8 mb-8">
      <div class="grid gap-6 lg:grid-cols-2">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Verification
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Verify your identity and details
          </p>
          <div class="grid gap-6 mt-6">
            <img src="@/assets/security.svg" />
            <div class="flex">
              <img class="object-contain mr-4" src="@/assets/credas.png" />
              <p class="text-sm text-gray-500 border-l-2 pl-4">
                We selected Credas as our technology partner for the online
                provision of client identification.
              </p>
            </div>
          </div>
        </div>
        <div
          class="
            bg-gray-50
            rounded-2xl
            lg:w-72
            grid
            gap-4
            content-start
            px-5
            py-4
            h-fit-content
          "
        >
          <div
            v-if="user.verify_status === 'verifed'"
            class="
              bg-green-100
              text-green-800
              px-3
              py-2
              flex
              items-center
              gap-1
              text-sm
              leading-5
              font-semibold
              rounded-full
              max-w-min
            "
          >
            <CheckCircleIcon class="h-3" /> Verifed
          </div>
          <div
            v-else
            class="
              bg-yellow-100
              text-yellow-800
              px-3
              py-2
              flex
              items-center
              gap-1
              text-sm
              leading-5
              font-semibold
              rounded-full
              max-w-min
            "
          >
            <ClockIcon class="h-3" /> Pending
          </div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Identity Verification
          </h3>
          <p class="mt-1 text-sm">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat.
          </p>
          <ul class="text-sm">
            <li
              v-if="user.verify_status === 'verifed'"
              class="flex items-center"
            >
              <CheckCircleIcon class="h-4 w-4 text-green-800 mr-2" />UK Passport
            </li>
            <li v-else class="flex items-center">
              <ClockIcon class="h-4 w-4 text-yellow-800 mr-2" />UK Passport
            </li>
            <li
              v-if="user.verify_status === 'verifed'"
              class="flex items-center"
            >
              <CheckCircleIcon class="h-4 w-4 text-green-800 mr-2" />Selfie
            </li>
            <li v-else class="flex items-center">
              <ClockIcon class="h-4 w-4 text-yellow-800 mr-2" />Selfie
            </li>
            <li
              v-if="user.verify_status === 'verifed'"
              class="flex items-center"
            >
              <CheckCircleIcon class="h-4 w-4 text-green-800 mr-2" />AML Check
            </li>
            <li v-else class="flex items-center">
              <ClockIcon class="h-4 w-4 text-yellow-800 mr-2" />AML Check
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ClockIcon, CheckCircleIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  setup() {
    const store = useStore();
    return {
      user: computed(() => store.getters["user/user"]),
    };
  },
  components: {
    ClockIcon,
    CheckCircleIcon,
  },
};
</script>

